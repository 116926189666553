import React, { useEffect, useState } from 'react';
import PageHeader from "../../components/page-header";
import NewSideBar from "../../components/side-bar/new-sidebar";
import { apiUrl, pageUrl, protocol } from "../../utils/url";
import { useDispatch, useSelector } from "react-redux";
import copyComponent from "../../assets/img/copy-clipboard.svg";
import { toast } from "react-toastify";
import plusIcon from "../../assets/img/thin-plus-icon.svg";
import gearBlackIcon from "../../assets/img/gear.svg";
import dragIcon from "../../assets/img/page/drag-icon.svg";
import BlockIcon from "../../assets/img/page/blockicon.svg";
import gearPageIcon from "../../assets/img/page/gear.svg";
import trashIcon from "../../assets/img/page/trash.svg";
import { CustomizeProductModal } from './product-settings';
import { hideLoader, showLoader } from '../loader';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import ThreeBoxPage from './product-settings/product-tabs/product-section/three-box-page';
import GobalblockPage from './product-settings/product-tabs/product-section/global-page';
import FaqsPage from './product-settings/product-tabs/product-section/faq-page';
import ReviewPage from './product-settings/product-tabs/product-section/review-page';
import ConfirmationModal from '../confirmation-modal';
import { Tab, Tabs } from 'react-bootstrap';
import ContentPage from './product-settings/product-tabs/content-page';
import DetailsPage from './product-settings/product-tabs/details-page';
import ProductTabs from './product-settings/product-tabs/product-tabs';
import yellowCircle from "../../assets/img/yellow-circle.svg"
import redCircle from "../../assets/img/red-circle.svg"
import purpleCircle from "../../assets/img/purple-circle.svg"
import AutoSaveMsg from '../../services/auto-save-msg';
// let script_tag = `<script src="https://sortablejs.github.io/Sortable/Sortable.js"></script>`
let script_tag = ``
const blockSortable = (setIframeURL,slug, product_slug) => {
    const el = document.querySelector('.product-blocks-main')
    let folders_sortable = Sortable?.create(el, {
        animation: 150,
        handle: '#drag',
        draggable: '.product-block',
        onUpdate: (event) => {
            let blocks = [];
            $('.product-blocks-main> .product-block').each((i, elm) => {
                let block = {
                    block_id: elm.dataset.block,
                    order_no: i
                };
                blocks.push(block);
            })

            if (blocks.length > 0)
                $.ajax({
                    type: 'POST',
                    url: `${apiUrl}/ProductFlow/sort`,
                    data: {
                        request_type: 'order',
                        blocks,
                        project: localStorage?.slug,
                        product: product_slug
                    },
                    headers: {
                        'Authorization': `Bearer ${localStorage?.getItem("token")}`
                    },
                    dataType: 'json',
                    success: (response) => {
                        setTimeout(() => {
                            setIframeURL(protocol + '' + pageUrl + '/' + slug + '/' + product_slug  + '?iframe=1&v=' + Math.random(100));
                        }, 500);
                    }
                });
        }
    });
}

function EditProduct() {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [error, setError] = useState([]);
    const { edit, product_id} = location?.state || {};
    const handleSubmitData = () => {
        return 
      }
    useEffect(()=>{
        if (!product_id) {
            history.push(basePath + '/products')
        }
    },[])
    const { basePath, slug } = useSelector(state => state?.slugReducer);
    const [showModal, setShowModal] = useState({show:false,from:null});
    const [productSlug, setProductSlug] = useState();
    const [showSectionChildModal, setShowSectionChildModal] = useState();
    const [productBlocks, setProductBlocks] = useState([]);
    const [iframeURL, setIframeURL] = useState(1);
    const [iframeIndex, setIframeIndex] = useState(1);
    const [productData, setProductData] = useState({});
    const [count, setCount] = useState(0);
    const [confirmationModalShow, setconfirmationModalShow] = useState({ show: false, text: null });
    const detailValues = useSelector(state => state?.productDetailReducer);
    const contentValues = useSelector(state => state?.productContentReducer);
    const checkoutValues = useSelector(state => state?.productCheckoutReducer);
    const tierValues = useSelector(state => state?.productTierReducer);

    const copyingText = (text) => navigator.clipboard.writeText(text).then((r => toast.success("Successfully Copied")));
    const draggableIcon = () => <img src={dragIcon} id="drag" className="me-3 cursor-grab" alt="" />
    const showProductsSectionModal = (from) => {
        setShowModal({show:true,from:from})
    };
    const close = () => {
        setError(null);
        setShowSectionChildModal(false)
    };
    const editProduct = (product_id) => {
        setCount(0);
        const api = apiUrl + "/ProductFlow/edit/" + product_id;
        showLoader()
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
            const setOptions = {
                method: "GET",
                headers: myHeaders,
            }
            fetch(api, setOptions)
            .then(async (res) => {
                const response = await res?.json();
                if (response?.status_code == 200) {
                    dispatch({ type: 'SET_PRODUCT_DETAIL', payload: {
                        ...detailValues,
                        title: response?.data?.name,
                        short_description: response?.data?.short_description,
                        product_slug: response?.data?.slug,
                        price_type: response?.data?.price_type,
                        price: response?.data?.price,
                        min_price: response?.data?.price_type !== "suggested_price" ? 0 : response?.data?.price,
                        suggested_price: response?.data?.suggested_price,
                        currency: response?.data?.currency,
                        // cover_image: response?.data?.cover_images,
                    } });
                    
                    dispatch({ type: 'SET_PRODUCT_CONTENT', payload: {
                        ...contentValues,
                        content: response?.data?.content,
                        duration: response?.data?.calendar?.length > 0 ? response?.data?.calendar[0]?.duration : 15,
                        custom_meet_link: response?.data?.calendar?.length > 0 ? response?.data?.calendar[0]?.custom_meet_link : null,
                        timezone: response?.data?.calendar?.length > 0 ? response?.data?.calendar[0]?.timezone : null,
                        meeting_location: response?.data?.calendar?.length > 0 ? response?.data?.calendar[0]?.meeting_location : null,
                        content_file_name: response?.data?.content_file_name,
                        content_url: response?.data?.content_url,
                        product_content_data:response?.data?.product_content_data
                    } });
                    const updatedCheckoutQuestion = response?.data?.checkoutquestion.map(item => ({
                        questions: item.questions,
                        isRequired: item.isrequired == true,
                    }));
                    dispatch({ type: 'SET_PRODUCT_CHECKOUT', payload: {
                        ...checkoutValues,
                        product_theme: response?.data?.product_theme,
                        accent_theme: response?.data?.accent_theme,
                        buy_button_text: response?.data?.buy_button_text,
                        checkout_question:updatedCheckoutQuestion
                    } });
                    dispatch({ type: 'SET_PRODUCT_IMAGES', payload: {
                        cover_image: response?.data?.cover_images,
                    } });
                    if (response?.data?.price_type == 'membership') {
                        dispatch({ type: 'SET_PRODUCT_TIER', payload: {
                            ...tierValues,
                            tiers: response?.data?.tiers?.length > 0 ? response?.data?.tiers : [{
                                tier_name:"",
                                price:0,
                                type:"monthly"
                            }],
                        } })
                    }
                    setTimeout(() => {
                        setCount(1);
                    }, 3000);
                    setProductData(response?.data)
                    setProductSlug(response?.data?.slug)
                    setProductBlocks(response?.data?.product_blocks)
                    setIframeURL(protocol + '' + pageUrl + '/' + slug + '/' + response?.data?.slug  + '?iframe=1');
                }
            hideLoader();
          }).catch(() => {
            hideLoader();
          });
      }
      useEffect(() => {
        // console.log("editProduct true")
        if (product_id) {
          editProduct(product_id);
        }else if(localStorage?.product_id){
          editProduct(localStorage.getItem("product_id"));
        }
        }, []);
    const handleSubmit = (values,tab) =>{
        const api = apiUrl + "/ProductFlow/create-block";
        showLoader()
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
            const formdata = new FormData();
            formdata.append("project", slug);
            formdata.append("block", tab);
            formdata.append("product_slug", productData?.slug);
            if(tab == 'three_box'){
                formdata.append('boxes', JSON.stringify(values));
            }else if(tab == 'faq'){
                formdata.append('faqs', JSON.stringify(values));
            }else if(tab == 'reviews'){
                formdata.append('reviews', JSON.stringify(values));
            }else if(tab == 'pricing'){
                formdata.append('pricing', JSON.stringify(values));
            }else{
                for (const key in values) {
                    if (Object.hasOwnProperty.call(values, key)) {
                        formdata.append(key, values[key]);
                    }
                }
            }
            const setOptions = {
                method: "POST",
                body: formdata,
                headers: myHeaders,
            }
            fetch(api, setOptions)
            .then(async (res) => {
                const response = await res?.json();
                if (response?.status_code == 200) {
                    setShowSectionChildModal(false)
                    setTimeout(() => {
                        editProduct(product_id)
                        setIframeURL(protocol + '' + pageUrl + '/' + slug + '/' + productData?.slug  + '?iframe=1');
                        setIframeIndex(iframeIndex + 1);
                        hideLoader();
                    }, 1000);
                    toast.success(response?.status_message)
                }else{
                    setError(Object.values(response?.errors));
                    if (Object.keys(response?.errors).length > 0)
                        $(".modal-body").scrollTop(0);
                    hideLoader();
                }
            hideLoader();
          }).catch(() => {
            hideLoader();
          });
    }
    const handleUpdate = (values,tab,block_id) =>{
        const api = apiUrl + "/ProductFlow/update-block";
        showLoader()
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
            const formdata = new FormData();
            formdata.append("project", slug);
            formdata.append("block", tab);
            formdata.append("product_slug", productData?.slug);
            formdata.append("block_id", block_id);
            if(tab == 'three_box'){
                formdata.append('boxes', JSON.stringify(values));
            }else if(tab == 'faq'){
                formdata.append('faqs', JSON.stringify(values));
            }else if(tab == 'reviews'){
                formdata.append('reviews', JSON.stringify(values));
            }else if(tab == 'pricing'){
                formdata.append('pricing', JSON.stringify(values));
            }else{
                for (const key in values) {
                    if (Object.hasOwnProperty.call(values, key)) {
                        formdata.append(key, values[key]);
                    }
                }
            }
            const setOptions = {
                method: "POST",
                body: formdata,
                headers: myHeaders,
            }
            fetch(api, setOptions)
            .then(async (res) => {
                const response = await res?.json();
                if (response?.status_code == 200) {
                    setShowSectionChildModal(false)
                    setTimeout(() => {
                        editProduct(product_id)
                        setIframeURL(protocol + '' + pageUrl + '/' + slug + '/' + productData?.slug  + '?iframe=1');
                        setIframeIndex(iframeIndex + 1);
                        hideLoader();
                    }, 1000);
                    toast.success(response?.status_message)
                }else{
                    setError(Object.values(response?.errors));
                    if (Object.keys(response?.errors).length > 0)
                        $(".modal-body").scrollTop(0);
                    hideLoader();
                }
            hideLoader();
          }).catch(() => {
            hideLoader();
          });
    }
    const editModal = (e, data) => {
        if (data?.type == "three_box"){
            setShowSectionChildModal({
                show: true,
                id: e.target.dataset?.id,
                meta: data?.meta,
                type:"threeBox"
            })
        }else if (data?.type == "horizontal"){
            setShowSectionChildModal({
                show: true,
                id: e.target.dataset?.id,
                meta: data?.meta,
                type:"horizontal"
            })
        }else if (data?.type == "vertical"){
            setShowSectionChildModal({
                show: true,
                id: e.target.dataset?.id,
                meta: data?.meta,
                type:"vertical"
            })
        }else if (data?.type == "faq"){
            setShowSectionChildModal({
                show: true,
                id: e.target.dataset?.id,
                meta: data?.meta,
                type:"faq"
            })
        }else if (data?.type == "reviews"){
            setShowSectionChildModal({
                show: true,
                id: e.target.dataset?.id,
                meta: data?.meta,
                type:"reviews"
            })
        }else if (data?.type == "pricing"){
            setShowSectionChildModal({
                show: true,
                id: e.target.dataset?.id,
                meta: data?.meta,
                type:"pricing"
            })
        }
    }
    const confirmedDeleteBlock = (e) => {
        // e.preventDefault();
        showLoader()
        if (confirmationModalShow?.id) {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
            var requestOptions = {
            };
            var api = `${apiUrl}/ProductFlow/delete-block/${confirmationModalShow?.id}`
            requestOptions = {
                method: 'DELETE',
                headers: myHeaders,
                redirect: 'follow'
            }
            fetch(api, requestOptions)
                .then(async (res) => {
                    const response = await res?.json();
                    if (response?.status_code == 200) {
                        editProduct(product_id);
                    }
                    else {
                        toast.error(response?.status_message)
                        hideLoader()
                    }
                    setconfirmationModalShow({ show: false, text: null, id: null })
                })
                .catch(() => {
                    setconfirmationModalShow({ show: false, text: null, id: null })
                    hideLoader()
                })
        }

    }
    const onDelete = (id) => setconfirmationModalShow({ show: true, text: "Are you sure you want to delete this block?", id: id, callBackTo: "delete" })

    // useEffect(() => {
    //     $("body").append(script_tag)

    //     $(document).ready(() => {

    //         setTimeout(() => {
    //             // folders sort
    //             blockSortable(setIframeURL,slug,productSlug)
    //         }, 2000);
    //     })

    // }, [productSlug])
    const goBack = () => {
        dispatch({ type: "RESET_PRODUCT_DETAILS" })
        history.push("/project/" + slug + "/products");
    }
  return (
    <div  id="wrapper">
          <PageHeader />
          <div className="page-editor-main">
                <NewSideBar/>

            <div className='product-main-cont'>
                <div className='product-page-header'>
                    <div className='product-page-header-start cursor-pointer'>
                        <span class="me-2 d-flex align-items-center gap-2">
                            <span onClick={()=>{goBack();}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                    <path d="M14 7.5H1M1 7.5L7.5 14M1 7.5L7.5 1" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                            <span className='product-page-header-title'>
                                {productData?.name}
                            </span>
                        </span>
                        <span class={`new-product-types`}>
                            <span className='small-box'>
                            </span>
                            <span>
                                {productData?.product_type} 
                            </span>
                        </span>

                    </div>
                    <div className='product-page-header-end'>
                        <a href={`${protocol + pageUrl}/${slug}/${productSlug}`} target="blank" className='btn-grey text-decoration-none settings-button-primary'>
                            Preview 
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                <path d="M1 7L7 1M7 1H3M7 1V5" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </a>
                        <div className="pagePrev-link">
                            <a className="text-decoration-none" href={`${protocol + pageUrl}/${slug}/${productSlug}`} target="blank">
                                {pageUrl}/{slug}/{productSlug}
                            </a>
                        </div>
                        <button className='blue-shadow-button text-nowrap' type='button' onClick={() => copyingText(`${pageUrl}/${slug}/${productSlug}`)}>
                            Copy Link
                        </button>
                    </div>
                </div>
                <div className='edit-product-page hidden-scroll'>
                    <div className='w-100 hidden-scroll overflow-auto '>
                    <ProductTabs count={count} setCount={setCount} editProduct={editProduct} iframeIndex={iframeIndex} setIframeURL={setIframeURL} setIframeIndex={setIframeIndex} showProductsSectionModal={showProductsSectionModal} data={productData} error={error} setError={setError} onDelete={onDelete} editModal={editModal} product_id={product_id} setProductSlug={setProductSlug}/>
                    </div>
                {/* <div className='Edit-area'>
                    <h2 className='mainTitle'>Edit Product</h2>

                    <div className="Product-Editor mt-30px">
                        <button className="bg-blue" onClick={() => showProductsSectionModal('add_block')}>
                            <img src={plusIcon} alt="" width={10}/>
                            <span>Add Section</span>
                        </button>
                        <button className="bg-blue bg-gray" onClick={() => showProductsSectionModal('customize_product')}>
                            <img src={gearBlackIcon} width={14} alt="" />
                            <span >Product Settings</span>
                        </button>
                    </div>
                    <div className='product-blocks-main' id="product-blocks-main">
                    {productBlocks?.length > 0 && productBlocks?.map((val, index) => {
                    if (val?.type == "three_box"){
                    return (
                    <div data-block={val?.id} className='mt-20px product-block'>
                          <div className="editor-linku" id="folder-card">
                              <div className="d-flex align-items-center editor-link-first-half">
                                  {draggableIcon()}
                                  <img src={BlockIcon} className="me-3" alt="" />
                                  <div className="d-flex align-items-start justify-content-center flex-column">
                                      
                                      <h5 className="editor-link-headings mb-0">Feature</h5>
                                  </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-end editor-link-second-half">
                                  <div className="d-flex align-items-center">
                                      <button className="btn btn-sm link-action-button product-link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                          <img src={gearPageIcon} width="14px" alt="" data-id={val?.id} onClick={(e) => editModal(e, val)}  />
                                      </button>
                                      <button className="btn btn-sm link-action-button product-link-action-button ms-2">
                                          <img src={trashIcon} onClick={()=> onDelete(val?.id)} width="14px" alt="" />
                                      </button>
                                  </div>
                              </div>
                          </div>
                    </div>
                    )}else if (val?.type == "horizontal"){
                        return (
                            <div data-block={val?.id} className='mt-20px product-block'>
                                <div className="editor-linku" id="folder-card">
                                    <div className="d-flex align-items-center editor-link-first-half">
                                        {draggableIcon()}
                                        <img src={BlockIcon} className="me-3" alt="" />
                                        <div className="d-flex align-items-start justify-content-center flex-column">
                                            <h5 className="editor-link-headings mb-0">{JSON?.parse(val?.meta)?.title}</h5>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-end editor-link-second-half">
                                        <div className="d-flex align-items-center">
                                            <button className="btn btn-sm link-action-button product-link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                <img src={gearPageIcon} width="14px" alt="" data-id={val?.id} onClick={(e) => editModal(e, val)}  />
                                            </button>
                                            <button className="btn btn-sm link-action-button product-link-action-button ms-2">
                                                <img src={trashIcon} onClick={()=> onDelete(val?.id)} width="14px" alt="" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }else if (val?.type == "vertical"){
                        return (
                            <div data-block={val?.id} className='mt-20px product-block'>
                                <div className="editor-linku" id="folder-card">
                                    <div className="d-flex align-items-center editor-link-first-half">
                                        {draggableIcon()}
                                        <img src={BlockIcon} className="me-3" alt="" />
                                        <div className="d-flex align-items-start justify-content-center flex-column">
                                            <h5 className="editor-link-headings mb-0">{JSON?.parse(val?.meta)?.title}</h5>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-end editor-link-second-half">
                                        <div className="d-flex align-items-center">
                                            <button className="btn btn-sm link-action-button product-link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                <img src={gearPageIcon} width="14px" alt="" data-id={val?.id} onClick={(e) => editModal(e, val)}  />
                                            </button>
                                            <button className="btn btn-sm link-action-button product-link-action-button ms-2">
                                                <img src={trashIcon} onClick={()=> onDelete(val?.id)} width="14px" alt="" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }else if (val?.type == "faq"){
                        return (
                            <div data-block={val?.id} className='mt-20px product-block'>
                                <div className="editor-linku" id="folder-card">
                                    <div className="d-flex align-items-center editor-link-first-half">
                                        {draggableIcon()}
                                        <img src={BlockIcon} className="me-3" alt="" />
                                        <div className="d-flex align-items-start justify-content-center flex-column">
                                            
                                            <h5 className="editor-link-headings mb-0">{JSON?.parse(val?.meta)?.faq_heading}</h5>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-end editor-link-second-half">
                                        <div className="d-flex align-items-center">
                                            <button className="btn btn-sm link-action-button product-link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                <img src={gearPageIcon} width="14px" alt="" data-id={val?.id} onClick={(e) => editModal(e, val)}  />
                                            </button>
                                            <button className="btn btn-sm link-action-button product-link-action-button ms-2">
                                                <img src={trashIcon} onClick={()=> onDelete(val?.id)} width="14px" alt="" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }else if (val?.type == "reviews"){
                        return (
                            <div data-block={val?.id} className='mt-20px product-block'>
                                <div className="editor-linku" id="folder-card">
                                    <div className="d-flex align-items-center editor-link-first-half">
                                        {draggableIcon()}
                                        <img src={BlockIcon} className="me-3" alt="" />
                                        <div className="d-flex align-items-start justify-content-center flex-column">
                                            
                                            <h5 className="editor-link-headings mb-0">{JSON?.parse(val?.meta)?.title}</h5>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-end editor-link-second-half">
                                        <div className="d-flex align-items-center">
                                            <button className="btn btn-sm link-action-button product-link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                <img src={gearPageIcon} width="14px" alt="" data-id={val?.id} onClick={(e) => editModal(e, val)}  />
                                            </button>
                                            <button className="btn btn-sm link-action-button product-link-action-button ms-2">
                                                <img src={trashIcon} onClick={()=> onDelete(val?.id)} width="14px" alt="" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }else if (val?.type == "pricing"){
                        return (
                            <div data-block={val?.id} className='mt-20px product-block'>
                                <div className="editor-linku" id="folder-card">
                                    <div className="d-flex align-items-center editor-link-first-half">
                                        {draggableIcon()}
                                        <img src={BlockIcon} className="me-3" alt="" />
                                        <div className="d-flex align-items-start justify-content-center flex-column">
                                            
                                            <h5 className="editor-link-headings mb-0">{JSON?.parse(val?.meta)?.title}</h5>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-end editor-link-second-half">
                                        <div className="d-flex align-items-center">
                                            <button className="btn btn-sm link-action-button product-link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                <img src={gearPageIcon} width="14px" alt="" data-id={val?.id} onClick={(e) => editModal(e, val)}  />
                                            </button>
                                            <button className="btn btn-sm link-action-button product-link-action-button ms-2">
                                                <img src={trashIcon} onClick={()=> onDelete(val?.id)} width="14px" alt="" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    })}
                    </div>
                    <div className="editor-header d-flex align-items-center justify-content-start mt-20px">
                        <button className="bg-blue w-100" onClick={() => showProductsSectionModal('add_block')}>
                            <img src={plusIcon} alt="" width={10}/>
                            <span>Add Section</span>
                        </button>
                    </div>
                </div> */}

              

                {/* <div className="rightbar-u"> */}

                        {/* <div className="pagePrev-link" style={{background:"#F3F3F3px"}}>
                            <div className='prevlink-text'>
                                <a className="text-decoration-none text-dark" href={`${protocol + pageUrl}/${slug}/${productSlug}`} target="blank"> <span className="ms-2">{pageUrl}/{slug}/{productSlug}</span> </a>
                            </div>
                            <button className='Blue-btn' type='button' onClick={() => copyingText(`${pageUrl}/${slug}/${productSlug}`)}>
                                <img className="cursor-pointer" src={copyComponent} width="16px" alt="" />
                                Copy
                            </button>
                        </div> */}
                        <div className="Product-preview-mobile">
                            <iframe className='w-100 h-100' src={iframeURL} key={iframeIndex} frameBorder="0" ></iframe>
                            <div className="page-preview-loader">
                                <div className="spinner">
                                    <div className="bar1"></div>
                                    <div className="bar2"></div>
                                    <div className="bar3"></div>
                                    <div className="bar4"></div>
                                    <div className="bar5"></div>
                                    <div className="bar6"></div>
                                    <div className="bar7"></div>
                                    <div className="bar8"></div>
                                    <div className="bar9"></div>
                                    <div className="bar10"></div>
                                    <div className="bar11"></div>
                                    <div className="bar12"></div>
                                </div>
                            </div>
                        </div>
                </div>
            <AutoSaveMsg/>
            </div>
            {/* </div> */}
        </div>
        {confirmationModalShow?.show && <ConfirmationModal confirmationModalShow={confirmationModalShow} setShow={setconfirmationModalShow} callback={confirmedDeleteBlock} />}
        {/* {console.log("showSectionChildModal",showSectionChildModal)} */}
        {showModal.show && <CustomizeProductModal show={showModal} setShow={setShowModal} data={productData} setIframeURL={setIframeURL} iframeIndex={iframeIndex} setIframeIndex={setIframeIndex} editProduct={editProduct} edit={edit} product_id={product_id} setShowSectionChildModal={setShowSectionChildModal}/> }
        {showSectionChildModal?.type == 'threeBox' ? <ThreeBoxPage error={error} setError={setError} handleUpdate={handleUpdate} data={showSectionChildModal} showSectionChildModal={true} setShowSectionChildModal={setShowSectionChildModal} handleSubmit={handleSubmit} close={close}/> : null}
        {showSectionChildModal?.type == 'horizontal' && <GobalblockPage type={'horizontal'} error={error} setError={setError} showSectionChildModal={true && 'horizontal'} setShowSectionChildModal={setShowSectionChildModal} handleUpdate={handleUpdate} data={showSectionChildModal} handleSubmit={handleSubmit} close={close}/>}
        {showSectionChildModal?.type == 'vertical' && <GobalblockPage data={showSectionChildModal} type={'vertical'} error={error} setError={setError} showSectionChildModal={true && 'vertical'} setShowSectionChildModal={setShowSectionChildModal} handleUpdate={handleUpdate} handleSubmit={handleSubmit} close={close}/>}
        {showSectionChildModal?.type == 'faq' && <FaqsPage  data={showSectionChildModal} showSectionChildModal={true} error={error} setError={setError} setShowSectionChildModal={setShowSectionChildModal} close={close} handleSubmit={handleSubmit} handleUpdate={handleUpdate}/>}
        {showSectionChildModal?.type == 'reviews' && <ReviewPage showSectionChildModal={true} error={error} setError={setError} setShowSectionChildModal={setShowSectionChildModal} close={close} handleSubmit={handleSubmit} handleUpdate={handleUpdate} data={showSectionChildModal}/>}
        {showSectionChildModal?.type == 'pricing' && <GobalblockPage type={'pricing'} error={error} setError={setError} showSectionChildModal={true && 'pricing'} setShowSectionChildModal={setShowSectionChildModal} close={close} handleSubmit={handleSubmit} handleUpdate={handleUpdate} data={showSectionChildModal}/>}
    </div>
  )
}

export default EditProduct