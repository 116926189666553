import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const AutoSaveMsg = () => {
  const isSaving = useSelector(state => state?.productSavingReducer);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState(false);
  useEffect(() => {
    if (isSaving?.show) {
      setTimeout(() => {
        setShowMessage(true);
      }, 100);
      setTimeout(() => {
        setShowMessage(false);
      }, 4000);
    }
  }, [isSaving?.show]);

  useEffect(() => {
    if (isSaving?.message) {
        setMessage(isSaving?.message);
    }
  }, [isSaving?.message]);
  return (
    <div className="msgs">
      {/* Save Success Message */}
        <div className={`msg-container ${isSaving?.type} ${showMessage ? 'fly-right' : ''}`}>
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none">
                  <path d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>  
              </span> 
              <span className='font-weight-500'>
                {message}
              </span>
        </div>
    </div>
  );
};

export default AutoSaveMsg;
