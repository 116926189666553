const INIT_STATE = {
    cover_image:[],
    deleted_cover_image:[],
}
const productImagesReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'SET_PRODUCT_IMAGES':
            return action.payload
        case 'RESET_PRODUCT_IMAGES':
            return INIT_STATE
        default:
            return state
    }
}
export default productImagesReducer