import React from 'react'
import chevron_down from "../../assets/img/modals/chevron-down.svg"

const FilterDropdowns = ({isGraphFilter = true, dateFilters,statsData,analyticsGraphFilterClick,selectedFilter, handleFilterSelect}) => {
    return (
        <>
            {
                isGraphFilter &&
                <>
                    <div className="filter-dropdown date-filter-dropdown dropdown-toggle noselect" id="filter-dropdown"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <span className="">Filter:</span>
                        <span className="filter-dropdown-menu-selected ms-2 font-weight-500">All</span>
                        <img src={chevron_down} width="10px" alt="" className="ms-1" />
                    </div>

                    <ul className="dropdown-menu filter-dropdown-menu analytics-filter-dropdown-menu"
                        aria-labelledby="filter-dropdown" onClick={e => e.stopPropagation()}>
                        <li>
                            <a className="dropdown-item active" href="#" data-value="All">
                                <span className="me-5 d-flex align-items-center justify-content-betwen">
                                    <input type="checkbox" value="unique_visitors" name="analytics-graph-filter[]" id="graph-label-1" defaultChecked={true} onClick={analyticsGraphFilterClick} />
                                    <label htmlFor="graph-label-1">Unique Visitors</label>
                                </span>
                                <small className="text-muted">{statsData?.unique_visitors?.count}</small>
                            </a>
                        </li>

                        <li>
                            <a className="dropdown-item active" href="#" data-value="All">
                                <span className="me-5 d-flex align-items-center justify-content-betwen">
                                    <input type="checkbox" value="impressions" name="analytics-graph-filter[]" id="graph-label-2" defaultChecked={true} onClick={analyticsGraphFilterClick} />
                                    <label htmlFor="graph-label-2">Impressions</label>
                                </span>
                                <small className="text-muted">{statsData?.impressions?.count}</small>
                            </a>
                        </li>
                        <li>
                            <a className="dropdown-item active" href="#" data-value="All">
                                <span className="me-5 d-flex align-items-center justify-content-betwen">
                                    <input type="checkbox" value="time_on_page" name="analytics-graph-filter[]" id="graph-label-3" defaultChecked={true} onClick={analyticsGraphFilterClick} />
                                    <label htmlFor="graph-label-3">Visit Duration</label>
                                </span>
                                <small className="text-muted">${statsData?.time_on_page?.count}</small>
                            </a>
                        </li>
                        <li>
                            <a className="dropdown-item active" href="#" data-value="All">
                                <span className="me-5 d-flex align-items-center justify-content-betwen">
                                    <input type="checkbox" value="link_clicks" name="analytics-graph-filter[]" id="graph-label-4" defaultChecked={true} onClick={analyticsGraphFilterClick} />
                                    <label htmlFor="graph-label-4">Link Clicks</label>
                                </span>
                                <small className="text-muted">{statsData?.link_clicks?.count}</small>
                            </a>
                        </li>
                        <li>
                            <a className="dropdown-item active" href="#" data-value="All">
                                <span className="me-5 d-flex align-items-center justify-content-betwen">
                                    <input type="checkbox" value="click_rate" name="analytics-graph-filter[]" id="graph-label-5" defaultChecked={true} onClick={analyticsGraphFilterClick} />
                                    <label htmlFor="graph-label-5">Click Rate</label>
                                </span>
                                <small className="text-muted">{statsData?.click_rate?.count}</small>
                            </a>
                        </li>

                    </ul>
                </>
            }
            <div className="ms-1 date-filter-dropdown dropdown-toggle noselect"
                id="date-filter-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                <span className="date-filter-dropdown-menu-selected font-weight-500">{selectedFilter}</span>
                <img src={chevron_down} width="10px" alt="" className="ms-1" />
            </div>

            <ul className="dropdown-menu">
                {dateFilters && dateFilters?.map((filter, index) => (
                    <li key={index} onClick={() => handleFilterSelect(filter.label, filter?.value)}>
                        <div className="filter-dropdown-item" >{filter?.label}</div>
                    </li>
                ))}
                <li key={dateFilters?.length} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleFilterSelect(null, 'custom')
                }}>
                    <div className="filter-dropdown-item noselect"
                        id="custom-date-filter-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                        <span className="font-weight-500">Custom Range</span>
                    </div>
                    <ul className="dropdown-menu" aria-labelledby="custom-date-filter-dropdown" onClick={e => e.stopPropagation()}>
                        <li>
                            <div id="calendar-picker"></div>
                        </li>
                    </ul>
                </li>
            </ul>
        </>
    )
}

export default FilterDropdowns