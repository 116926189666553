import returnIcon from "../../../../assets/img/modals/return.svg";
// import * as socialsOptions from "../../../utils/socials.json"
import { useState, useEffect, useCallback } from "react";
import productType from "../../../../assets/img/product-type-icon.svg";
import lightmodeicon from "../../../../assets/img/products/lightmodeicon.svg";
import iro from "@jaames/iro";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import questionIcon from "../../../../assets/img/products/questionicon.svg";
import addFaqIcon from "../../../../assets/img/products/add-faq-btn.svg";
import removeicon from "../../../../assets/img/products/removeicon.svg";
import { useDispatch, useSelector } from "react-redux";


const CheckoutPage = ({ data, count, handleSubmit , error, setError  }) => {
    const dispatch = useDispatch();
    const [buy_button_text, set_buy_button_text] = useState('Buy Now');
    const [checkout_question, setCheckout_Question] = useState([{ questions: "", isRequired: false, }]);
    const [product_theme, setProductTheme] = useState(data?.product_theme);
    const [accent_theme, setAccent_Theme] = useState(data?.accent_theme);
    const [colorPickerLoaded, setColorPickerLoaded] = useState(false);
    const values = useSelector(state => state?.productCheckoutReducer);
    const [timer, setTimer] = useState(null);
    const [colorWheel4, setColorWheel4] = useState();
    // useEffect(()=>{
    //     if (data) {
    //         setProductTheme(data?.product_theme);
    //         setAccent_Theme(data?.accent_theme);
    //         set_buy_button_text(data?.buy_button_text);
    //     }
    // },[data])
    useEffect(() => {
        setColorWheel4(
            new iro.ColorPicker("#colorWheelDemo4", {
                width: 100,
                color: values?.accent_theme,
                borderWidth: 1,
                borderColor: "#fff",
                display: "flex",
            })
        )
    },[])
    colorWheel4?.on("color:change", function (color, changes) {
        $("#hex_code4").val(color.hexString);
        $("#rgb_code4").val(
            colorWheel4.color.rgbString.replace("rgb(", "").replace(")", "")
        );
        // setAccent_Theme(color.hexString);
        dispatch({ type: "SET_PRODUCT_CHECKOUT", payload: {  ...values, accent_theme:color?.hexString } })
        // changeDelay();
    });
      function handleQuestion() {
        var newdata = [...values?.checkout_question];
        newdata.push({ questions: "", isRequired: false });
        // setCheckout_Question(newdata);
        dispatch({ type: "SET_PRODUCT_CHECKOUT", payload: {  ...values, checkout_question:newdata } })
        // changeDelay();
      }
    //   useEffect(() => {
    //     if (data?.checkoutquestion) {
    //         const updatedCheckoutQuestion = data?.checkoutquestion.map(item => ({
    //             questions: item.questions,
    //             isRequired: item.isrequired == true, // Convert "true" to true, and "false" to false
    //         }));
    
    //         // setCheckout_Question(updatedCheckoutQuestion);
    //     dispatch({ type: "SET_PRODUCT_CHECKOUT", payload: {  ...values, checkout_question:updatedCheckoutQuestion } })

    //     }
    // }, [data?.checkoutquestion]);
    function handleCheckFun(a, i) {
        const questionscheck = a.target.value;
        const tempCheckoutQuestion = [...values?.checkout_question];
        const currentObj = { ...tempCheckoutQuestion[i] };
        currentObj.questions = questionscheck;
        tempCheckoutQuestion[i] = currentObj;
        // setCheckout_Question([...tempCheckoutQuestion]);
        dispatch({ type: "SET_PRODUCT_CHECKOUT", payload: {  ...values, checkout_question:[...tempCheckoutQuestion] } })
        // changeDelay();
      }
    
      const delete_question = (index) => {
        // setCheckout_Question(prevState => {
        //     const updatedQuestions = [...prevState];
        //     updatedQuestions.splice(index, 1); // Remove the element at the specified index
        //     return updatedQuestions;
        // });
        const updatedQuestions = [...values?.checkout_question];
        updatedQuestions.splice(index, 1)
        dispatch({ type: "SET_PRODUCT_CHECKOUT", payload: {  ...values, checkout_question:updatedQuestions } })
        // changeDelay();

      };
    
      function handleswitch(a, i) {
        const isRequired = a.target.checked;
        const tempCheckoutQuestion = [...values?.checkout_question];
        const currentObj = { ...tempCheckoutQuestion[i] };
        currentObj.isRequired = isRequired;
        // currentObj.isRequired = !currentObj.isRequired;
        tempCheckoutQuestion[i] = currentObj;
        // setCheckout_Question([...tempCheckoutQuestion]);
        dispatch({ type: "SET_PRODUCT_CHECKOUT", payload: {  ...values, checkout_question:[...tempCheckoutQuestion] } })
        // changeDelay();

      }
      useEffect(() => {
          if (count > 0) {
            changeDelay();
        }
    }, [values]);
    const updateCheckout = () => {
        handleSubmit(null,values,'checkout');
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedValues = { ...values, [name]: value };
        dispatch({ type: "SET_PRODUCT_CHECKOUT", payload: updatedValues });
        // changeDelay(updatedValues)
    }

    function changeDelay() {
        if (timer) {
          clearTimeout(timer);
          setTimer(null);
        }else{
            setTimer(
              setTimeout(() => {
                updateCheckout()
              }, 3000)
            );
        }
    };
    return (
        <div class="product-pages">
            <h4 class="productHead1">General</h4>
                <form name="product_type" method="post" role="form">
                    <div className="notification-container"></div>
                    <div className="form-group mb-4 product-form-group">
                        <label className="productlabels">
                            Button Text
                            <div className="product-labels-description">Purchase Button text</div>
                        </label>
                        <div className="product-form-group-inputs">
                            <input onChange={handleChange} type="text" className="zaapGlobal-input" name="buy_button_text" placeholder="Got my new awesome course!" value={values?.buy_button_text} />
                        </div>
                    </div>
                    <div className="form-group mb-4 product-form-group">
                        <label className="productlabels">
                            Product Page Theme
                            <div className="product-labels-description">Purchase Button text</div>
                        </label>
                        <div className="up-in-toggle bg-f7f7f8  product-style theme-toggle">
                            <input type="radio" id="light_mode" name="product_theme" onChange={handleChange} value={"light"} className="light-inp" defaultChecked={values?.product_theme == "light" ? true : false}/>
                            <label for="light_mode" className="d-flex align-items-center justify-content-center">
                                {" "}
                                <img src={lightmodeicon} style={{ marginRight: 5 }} width="13px"/>
                                <span class="font-weight-500 dark-style">Light</span>
                            </label>
                            <input type="radio" id="dark_mode" name="product_theme" onChange={handleChange} value={"darkmode"} defaultChecked={values?.product_theme == "darkmode" ? true : false}/>
                            <label for="dark_mode" className="d-flex align-items-center justify-content-center">
                                {/* <img style={{ marginRight: 5 }} src={darkmodeicon} width="25px" /> */}
                                <svg width="13" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 12L6.28446 13.5689C6.54995 14.0999 6.68269 14.3654 6.86003 14.5954C7.01739 14.7996 7.20041 14.9826 7.40455 15.14C7.63462 15.3173 7.9001 15.4501 8.43108 15.7155L10 16.5L8.43108 17.2845C7.9001 17.5499 7.63462 17.6827 7.40455 17.86C7.20041 18.0174 7.01739 18.2004 6.86003 18.4046C6.68269 18.6346 6.54995 18.9001 6.28446 19.4311L5.5 21L4.71554 19.4311C4.45005 18.9001 4.31731 18.6346 4.13997 18.4046C3.98261 18.2004 3.79959 18.0174 3.59545 17.86C3.36538 17.6827 3.0999 17.5499 2.56892 17.2845L1 16.5L2.56892 15.7155C3.0999 15.4501 3.36538 15.3173 3.59545 15.14C3.79959 14.9826 3.98261 14.7996 4.13997 14.5954C4.31731 14.3654 4.45005 14.0999 4.71554 13.5689L5.5 12Z" stroke="#6B6B6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M14 1L15.1786 4.06442C15.4606 4.79765 15.6016 5.16426 15.8209 5.47264C16.0153 5.74595 16.254 5.98475 16.5274 6.17909C16.8357 6.39836 17.2024 6.53937 17.9356 6.82138L21 8L17.9356 9.17862C17.2024 9.46063 16.8357 9.60164 16.5274 9.82091C16.254 10.0153 16.0153 10.254 15.8209 10.5274C15.6016 10.8357 15.4606 11.2024 15.1786 11.9356L14 15L12.8214 11.9356C12.5394 11.2024 12.3984 10.8357 12.1791 10.5274C11.9847 10.254 11.746 10.0153 11.4726 9.82091C11.1643 9.60164 10.7976 9.46063 10.0644 9.17862L7 8L10.0644 6.82138C10.7976 6.53937 11.1643 6.39836 11.4726 6.17909C11.746 5.98475 11.9847 5.74595 12.1791 5.47264C12.3984 5.16426 12.5394 4.79765 12.8214 4.06442L14 1Z" stroke="#6B6B6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                                <span className="dark-style" style={{ marginLeft: 5 }}>Dark</span>

                            </label>
                        </div>
                    </div>
                    <div className="form-group mb-4 product-form-group">
                        <label className="productlabels">
                            Accent colour
                            <div className="product-labels-description">Purchase Button text</div>
                        </label>

                        <div className="d-flex justify-content-start align-items-center flex-wrap">
                            <div>
                                <div
                                    className="wheel"
                                    id="colorWheelDemo4"
                                ></div>
                            </div>
                            {/* <div style={{ paddingLeft: 10 }}>
                                {" "}
                                <input
                                    type="text"
                                    id="hex_code4"
                                    className="w-50  py-1"
                                    name="hex_code4"
                                    defaultValue={accent_theme}
                                    required="required"
                                    style={{ marginTop: 10, border: "none", outline: "none", fontWeight: "bold", color: "  rgba(14, 18, 59, 0.5)   " }}
                                />
                            </div> */}
                        </div>
                    </div>
                    <h4>Checkout Question</h4>
                        {values?.checkout_question?.map((ele, i) => {
                        return (
                            // !ele?.questions && i == 0?  '' :
                            <div key={i} className="checkout-question-input mb-1">
                                <input  className="zaapGlobal-input" value={ele.questions} type="text" name="title" onChange={(a) => {handleCheckFun(a, i);}} placeholder="what is  your username"/>
                                <div class="box-grey" style={{gap:"15px"}}>
                                    <div>Required?</div>
                                    <label className="link-switch">
                                        <input defaultChecked={ele.isRequired == 1 ? true : false} type="checkbox" className="" name="isrequired" value={ele.isRequired} onChange={(a) => handleswitch(a, i)}/>
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="box-grey cursor-pointer">
                                    <img src={removeicon} onClick={() => delete_question(i)} />
                                </div>
                            </div>
                        );
                        })}
                    <div className="mt-15px">
                        <button type="button" className="add-question-btn" onClick={handleQuestion}>
                            <span className="m-0">Add question</span>
                            <img src={addFaqIcon} width="18px" alt="" />
                        </button>
                    </div>
                    {/* <div class="text-center mt-4 d-flex submit-buttons-fixed">
                        <button type="button" onClick={(e)=>{updateCheckout(e)}} class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                            <b class="me-2 productlabel2">Save Changes</b>
                            <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                <img src={returnIcon} alt="" />
                            </small>
                        </button>
                    </div> */}
                </form>
        </div >
    )
}
export default CheckoutPage