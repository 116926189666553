import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import blockIcon from "../../../../assets/img/block-icon.svg";
import returnIcon from "../../../../assets/img/modals/return.svg";
import ErrorMessage from '../../../errors/errorMessage';
import { useDispatch, useSelector } from 'react-redux';
import imageIcon from "../../../../assets/img/image-icon.svg";
import { hideLoader, showLoader } from '../../../loader';

function ProductContentModal({ show, setShow }) {
    const [error, setError] = useState([]);
    const [value, setValue] = useState(show?.val);
    const dispatch = useDispatch();
    const values = useSelector(state => state?.productContentReducer);
    const close = () => {
        setValue(null)
        setError(null);
        setShow(null);
    }
    const handleChange = (e) => {
        if (value?.type == 'file') {
            setValue({ ...value, content: e.target.files[0], name:e.target.files[0]?.name  });
        }else{
            setValue({...value, [e.target.name]: e.target.value});
        }
    };
    const addContent = (e) => {
        showLoader();
        e.preventDefault();
        let updatedContentData = [...values?.product_content_data];
        if (show?.isEdit) {
            // index = updatedContentData.findIndex(item => item.id === value.id);
            updatedContentData[show?.index] = { ...value };
        } else {
          updatedContentData.push({ ...value });
        }
        dispatch({ type: "SET_PRODUCT_CONTENT", payload: { ...values, product_content_data: updatedContentData } });
        // setTimeout(() => {
          close();
          hideLoader();
        // }, 2000);
      };
    return (
        <Modal centered show={show?.show} onHide={close}>
            <div className="modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <form name="product_type" method="post" role="form" onSubmit={addContent}>
                            <div className="notification-container"></div>
                            <div className="form-group">
                                <img src={blockIcon} width="40px" alt="" />
                                <h4 className="productHead1 mt-15px">Add {value?.type == 'url'? 'URL':'Content'}</h4>
                            </div>
                            <ErrorMessage error={error} />
                            {value?.type == "file" && (
                                <>
                                    {!value?.name && <div
                                        className="form-group w-100 product-files-section"
                                        data-type="files"
                                    >
                                        <label className="text-gray font-weight-500 mt-3 mb-0 productlabel3">
                                            Upload your Product files, your customers will receive an email with a download link.
                                        </label>
                                        <div className="dropzone-wrapper dropzone-wrapper4 product-dropzone productnewdropzone">
                                            <div className="dropzone-desc">
                                                <img src={imageIcon} alt="" />
                                                <h6 className="mt-2 mb-1 text-gray">
                                                    Drag and drop files, or{" "}
                                                    <a className="text-primary decoration-none">click here to browse</a>
                                                </h6>
                                                <small className="text-muted-light">100MB max file size upload</small>
                                            </div>
                                            <input
                                                onChange={handleChange}
                                                type="file"
                                                accept=".png,.jpg,.jpeg,.gif,.bmp,.pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.zip,.rar,.svg"
                                                name="content_file"
                                                className="dropzone dropzone4"
                                                required
                                            />
                                        </div>
                                    </div>}

                                    {value?.name && <div style={{ display: "flex", justifyContent: "space-between", marginTop: 10 }}>
                                        <p>{value?.name}</p>
                                        {/* <img src={removeicon} width="3%" style={{ cursor: "pointer" }} onClick={handleRemoveUrl} /> */}
                                    </div>}
                                </>
                            )}

                            {value?.type == "url" && (
                                <div>
                                    <div className="form-group mt-2 w-100 product-files-section">
                                        <label className="text-gray font-weight-500 mt-3 mb-2">
                                            <small>
                                                Enter Name
                                                <small className="text-red">*</small>
                                            </small>
                                        </label>
                                        <input
                                            type="text"
                                            className="zaapGlobal-input"
                                            name="name"
                                            maxLength={50}
                                            placeholder="Please Enter Name"
                                            onChange={handleChange}
                                            value={value?.name}
                                            required
                                        />
                                    </div>
                                    <div
                                        className="form-group mt-2 w-100 product-files-section"
                                        data-type="url"
                                    >
                                        <label className="text-gray font-weight-500 mt-3 mb-2">
                                            <small>
                                                Redirect to a URL after purchase
                                                <small className="text-red">*</small>
                                            </small>
                                        </label>
                                        <input
                                            type="url"
                                            className="zaapGlobal-input"
                                            name="content"
                                            placeholder="Please Enter Redirect URL"
                                            onChange={handleChange}
                                            value={value?.content}
                                            required
                                        />
                                    </div>
                                </div>
                            )}

                            <div class="text-center d-flex submit-buttons-fixed mt-20px">
                                <button type="button" onClick={close} class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center save-btn-u">
                                    <span class="me-2 font-weight-500">Cancel</span>
                                    <small class="badge bg-light text-dark p-1">Esc</small>
                                </button>
                                <button type="submit" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center save-btn-u">
                                    <b class="me-2 font-weight-500" >Save Changes</b>
                                    <small class="badge bg-primary-dark text-dark px-2 py-1 mt-1 d-flex align-items-center justify-content-center">
                                        <img src={returnIcon} alt="" />
                                    </small>
                                </button>
                            </div>
                        </form>
                    </div>
                </div >
            </div >
        </Modal >
    )
}

export default ProductContentModal