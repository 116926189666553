import React from "react";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import membership from "../../assets/img/membership.svg";
import digital from "../../assets/img/digital-product.svg";
import coaching from "../../assets/img/coaching.svg";
import * as $ from "jquery";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import ProductMain from "./product-main";
import returnIcon from "../../assets/img/modals/return.svg";
import { hideLoader, showLoader } from "../loader";
import { toast } from "react-toastify";
import ErrorMessage from "../errors/errorMessage";
import { apiUrl } from "../../utils/url";

const ProductTypeModal = (props) => {
  const { isOpen, onHide } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [error, setError] = useState([]);
  const [showProductType, setShowProductType] = useState(null);
  const [values,setValues] = useState({title:""});
  // const [showCreateProduct, setShowCreateProduct] = useState(false);
  const isModalOpen = useSelector((state) => state?.upgradeModalReducer?.open);
  const onClose = () => onHide();
  const { basePath, slug } = useSelector((state) => state?.slugReducer);

  const showModal = (product_id) => {
    // setShowCreateProduct(true)
    history.push({
        pathname: `${basePath}/edit${showProductType}`,
        state: { show: true,product_id:[product_id], create:true}
    })
  }
  const handleChange = (e) => {
    setValues({ ...values ,[e.target.name]: e.target.value })
  }
  const handleSubmit = () => {
    const api = apiUrl + "/ProductFlow/create";
    showLoader()
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const formdata = new FormData();
        formdata.append("project", slug);
        formdata.append("title", values?.title);
        formdata.append("type", showProductType);
        const setOptions = {
            method: "POST",
            body: formdata,
            headers: myHeaders,
        }
        fetch(api, setOptions)
        .then(async (res) => {
            const response = await res?.json();
            if (response?.status_code == 200) {
                  hideLoader();
                  showModal(response?.data?.product_id);
                  toast.success(response?.status_message)
            }else{
                setError(Object.values(response?.errors));
                if (Object.keys(response?.errors).length > 0)
                  $(".modal-body").scrollTop(0);
                hideLoader();
            }
        // hideLoader();
      }).catch(() => {
        hideLoader();
      });
};
  return (
    <Modal centered className="product-type-modal" id="createFolderModal" show={isOpen} onHide={onClose}>
      <div className="modal-dialog-centered" role="document">
        <div className="modal-content">
              <div className="modal-header">
                <span className="fs-14px font-weight-500">What are you selling?</span>
              </div>
              <div className="modal-body">
              <ErrorMessage error={error} />
              <div className="form-group">
                <label className="productlabels">
                  Product Name
                </label>
                <input type="text" className="zaapGlobal-input" name="title" value={values?.title} onChange={handleChange} placeholder="Give Your Product A Name..." />
              </div>
              <div className="d-flex justify-content-start product-types mt-20px flex-wrap">
                <span onClick={() => setShowProductType("product")} className={`product-type-img cursor-pointer ${showProductType == "product" ? 'active' : null}`}>
                  <img src={digital} alt="" />
                </span>
                <span onClick={() => setShowProductType("call")} className={`product-type-img cursor-pointer ${showProductType == "call" ? 'active' : null}`}>
                  <img src={coaching} alt="" />
                </span>
                <span onClick={() => setShowProductType("membership")} className={`product-type-img cursor-pointer ${showProductType == "membership" ? 'active' : null}`}>
                  <img src={membership} alt="" />
                </span>
              </div>
              <div className="d-flex mt-20px gap-3 w-100 align-items-center ">
              <button type="button" className="payout-buttons btn-grey w-50" onClick={onClose}>Cancel</button>
              <button className="integration-btns integration-btn-primary mt-0 w-50" onClick={handleSubmit} >
                  <span>Next Step</span>
                  <small className="badge bg-primary-dark text-dark  d-flex align-items-center justify-content-center">
                      <img src={returnIcon} alt="" />
                  </small>
              </button>
              </div>
          </div>
        </div>
      </div>
      {/* {showCreateProduct && <Route path={basePath + "/products/:addProduct"} children={() => <ProductMain from={"productType"} closeProductType={onClose}/>}
            />} */}
    </Modal>
  );
};
export default ProductTypeModal;
