const INIT_STATE = {show:false, type: "success", message: ""};
const productSavingReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_PRODUCT_SAVING':
            return action.payload 
        case 'RESET_PRODUCT_SAVING':
            return INIT_STATE
        default:
            return state
    }

}
export default productSavingReducer