import React, { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const MapChart = ({
    mapData = am5geodata_worldLow,
    excludeCountries = ["AQ"],
    projection = "geoMercator",
    containerId = "chartdiv",
    countryData = [],
    height = "100%",
    width = "100%",
    strokeColor = "#868686",
    defaultCountryColor = "#FFFFFF",
    highlightBorder = "#0FB9E4",
}) => {
    useEffect(() => {
        const root = am5.Root.new(containerId);
        root.setThemes([am5themes_Animated.new(root)]);
        root._logo.dispose();

        const chart = root.container.children.push(
            am5map.MapChart.new(root, {
                panX: "translateX",
                panY: "translateY",
                projection: am5map[projection](),
            })
        );

        const polygonSeries = chart.series.push(
            am5map.MapPolygonSeries.new(root, {
                geoJSON: mapData,
                exclude: excludeCountries,
            })
        );


        polygonSeries.mapPolygons.template.adapters.add("fill", (fill, target) => {
            const isoCode = target.dataItem.get("id");
            return countryData[isoCode] || fill;
        });

        polygonSeries.mapPolygons.template.setAll({
            tooltipText: "{name}",
            toggleKey: "active",
            stroke: am5.color(strokeColor),
            fill: am5.color(defaultCountryColor),
            interactive: true
        });

        polygonSeries.mapPolygons.template.states.create("hover", {
            fill: am5.color(highlightBorder),
        });

        chart.set("zoomControl", am5map.ZoomControl.new(root, {}));
        chart.set("maxZoomLevel", 32);

        return () => {
            root.dispose();
        };
    }, [
        containerId,
        countryData,
        strokeColor,
        defaultCountryColor,
        highlightBorder,
        projection,
        mapData,
        excludeCountries,
    ]);

    return <div id={containerId} style={{ width, height }}></div>;
};


export default MapChart;